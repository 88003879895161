@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'























































































































































































.q-item__label--header
  padding: 0

.comptage-list
  padding: 1rem 0

.head-item
  border-right: 1px solid $grey-4
